import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { supabase } from './types/supabaseClient';
import { Session } from '@supabase/supabase-js';

import './App.css';
import Home from './components/Home';
import Login from './components/Login';

function App() {
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    supabase.auth.getSession()
      .then(({ data: { session }}) => {
        setSession(session);
      });

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    })
  }, []);

  return (
    <Container style={{ display: 'block', width: 700, padding: 30 }}>
      {!session ? <Login /> : <Home session={session} />}
    </Container>
  );
}

export default App;
