import { useEffect, useState } from 'react';
import { Session } from '@supabase/supabase-js';
import { supabase } from '../types/supabaseClient';
import { Todo } from '../types/todo';
import { Button, Form, Row, Col } from 'react-bootstrap';

interface HomeProps {
	session: Session;
}

function Home({ session } : HomeProps) {
	const [task, setTask] = useState('');
	const [loading, setLoading] = useState(false);
	const [todos, setTodos] = useState<Todo[]>([]);

	// Load todos on start
	useEffect(() => {
		loadTodos();
	}, []);

	// Add a new todo to our table
	const addTodo = async (e: any) => {
		e.preventDefault();

    const { user } = session;

		const newTodo = {
			title: task,
      user_id: user.id,
		};
		setLoading(true);
		const result = await supabase.from('list').insert(newTodo).select().single();
		setTodos([result.data, ...todos]);
		setLoading(false);
		setTask('');
	};

	// Get all data from the todos table
	const loadTodos = async () => {

    const { user } = session;

		let { data } = await supabase
			.from('list')
			.select()
      .eq('user_id', user.id)
			.order('created_at', { ascending: false });
		setTodos(data || []);
	};

  const updateTodo = async (ev: any, todo: Todo) => {
    const result = await supabase
      .from('list')
      .update({ done: ev.target.checked })
      .eq('id', todo.id)
      .select()
      .single();
    const updated = todos.map((item) => {
      if (item.id === todo.id) {
        item.done = result.data.done;
      }
      return item;
    });
  
    setTodos(updated);
  };
  
  const deleteTodo = async (todo: Todo) => {
    await supabase.from('list').delete().eq('id', todo.id);
    const updated = todos.filter((item) => item.id !== todo.id);
    setTodos(updated);
  };
  
	return (
    <>
			<h1>Toddy = Supabase + React 💡</h1>
      <Form onSubmit={addTodo}>
      <Row>
          <Form.Label column sm="2" htmlFor="todo">
            New Todo
          </Form.Label>
          <Col xs={7}>
            <Form.Control
              id="todo"
              placeholder="Buy milk"
              value={task}
              onChange={(e) => setTask(e.target.value)}
            />
          </Col>
          <Col>
            <Button disabled={loading} type="submit">Add Todo</Button>
          </Col>
      </Row>
        {todos.map((todo) => (
          <Row key={todo.id} >
            <Col xs={7}>
            <Form.Check
              className={todo.done ? 'done' : ''}
              inline
              label={todo.title}
              checked={todo.done}
              type="checkbox"
              onChange={(ev) => updateTodo(ev, todo)}
            />
            </Col>
            <Col>
            <Button variant="danger" onClick={() => deleteTodo(todo)}>Delete</Button>
            </Col>
          </Row>
        ))}
 			</Form>
    </>
	);
}

export default Home;