import { useState } from 'react';
import { supabase } from '../types/supabaseClient';
import { Button, Form, Row, Col, Alert } from 'react-bootstrap';

const emailRedirectTo = process.env.REACT_APP_SUPABASE_EMAIL_REDIRECT || 'http://localhost:3000';

function Login() {
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('name@email.com');
	const [msg, setMsg] = useState('Sign in via magic link with your email below...');

	const handleLogin = async (e: any) => {
		e.preventDefault();

		try {
			setLoading(true);
			const { error } = await supabase.auth.signInWithOtp({ email, options: { emailRedirectTo } });

			if (error) {
				setMsg(error.message);
			} else {
				setMsg('Check your emails now!');
			}
		} catch (error: any) {
			setMsg(error.error_description || error.message);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<h1>Welcome to Toddy 🚀</h1>
			{loading ? (
				'Sending magic link...'
			) : (
				<Form onSubmit={handleLogin}>
        <Row>
          <Form.Label column sm="2" htmlFor="email">
            Email
          </Form.Label>
          <Col xs={7}>
            <Form.Control
              id="email"
              type="email"
              placeholder="Your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Col>
          <Col>
					  <Button type="submit">Get Magic Link</Button>
          </Col>
        </Row>
				</Form>
			)}
			<Alert key='info' variant='info'>{msg}</Alert>
		</>
	);
}

export default Login;